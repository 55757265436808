import Model from '@/dc-it/models/Model'
import TagGroup from '@/dc-it/models/TagGroup'
import Field from '../types/Field'
import Relation from '../types/Relation'

export default class Tag extends Model {
    endpoint = 'tags';

    related = ['tag_group']

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre en español'),
      new Field('name_en', 'Nombre en inglés'),
      new Relation('tag_group', 'Grupo', TagGroup, 'name_es').setClearable(),
    ];

    clone = () => Tag;
}
